
  .nav-tabs{
    border-bottom: var(--bs-nav-tabs-border-width) 2px solid #0052a3;
  }
  .nav-link {
    color: rgb(19, 18, 18) !important;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    border-bottom: 2px solid #0d82fe !important;
    color: #0d82fe !important;
  }
  .other_bank p {
   padding:0;
   margin: 0;
  }
  .flex_icon{
    justify-content: center;
      align-items: center;
      display: flex;
      padding-right: 10px;
  }
  
  .nav-tabs .nav-link {
     border:#fff;
    }
  .outline_primary{
        text-align: end;
      color: #1385ff;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #1385ff;
  }
  
  #csv-input{
    display: none;
  }
  .close{
    background: #fff;
      border: none;
      color: #0d6efd;
      font-size: 20px;
  }
  .date_picker input{
    width: 100%;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
  }
  .header_flex{
    display: flex;
      align-items: center;
      justify-content: end;
  }
  .upload_btn_margin{
    margin: 0px 0px 0px 5px;
  }