.scheme img{
    width: 100%;
}
.addNewScheme img{
    width: 100%;
}
.addNewScheme{
    margin: 15px 10px;
}

