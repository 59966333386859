.barGraphImg{
    width: 100%;
}

.schemeBarGraphWrapper{
    background-color: #fff;
    border-radius: 15px;
    margin: 15px 10px;
    padding: 5px;
}

.schemeBarGraphWrapper canvas{
    height: 350px !important;
}

