.add_new_user {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 10px;
    border-radius: 0.25rem;
}

.add_new_user p {
    padding: 0;
    margin: 0;
    color: #000;
    justify-content: center;
    align-items: center;
    display: flex;

}

.eachsubheadBlock{
    border: 1px solid #d7cccc;
    border-radius: 10px;
    margin: 5px 0px;
}

.scheme_budget{
    font-size: 20px;
    font-weight: bold;
}