.add_new_user {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 10px;
    border-radius: 0.25rem;
}

.add_new_user p {
    padding: 0;
    margin: 0;
    color: #000;
    justify-content: center;
    align-items: center;
    display: flex;

}


.header_position {
    margin-bottom: 10px;
    padding: 20px;
    background-color: #fff;

}

.input_width {
    width: 100% !important;
}

#show_hide_password span {
    position: relative;
}

#show_hide_password span i {
    position: absolute;
    right: 10px;
    top: 10px;
}

.fingrt_print_backgroung {
    background-color: #f6f6f6;
    border: 1px solid #e4e2e2;
}